import React from "react"
import { Link } from "gatsby";

import Layout from "../components/layout"
import SEO from "../components/seo"

const ErrorPage = ({ pageContext }) => (
  <Layout>
    <SEO title={`Fel ${pageContext.status}`} />
    <h2>Här fanns det ingenting!</h2>
    <h4>{pageContext.status}</h4>
    <p>Gå tillbaka till <Link to="/">startsidan</Link> eller försök igen senare</p>
  </Layout>
)

export default ErrorPage
